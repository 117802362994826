.technology-item {
  .technology-item_title {
    display: block;
    font-size: 1.5rem;
    font-weight: 700;
    padding-bottom: 1rem;
    margin-bottom: 1rem;
    border-bottom: 1px solid #cccccc;
  }
  @media (max-width: 1084px) {
    .technology-item {
      margin-bottom: 30px;
    }

    .technology-item_desc p {
      text-align: left;
    }
  }
  @media (max-width: 767px) {
    .technology-item {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      margin-top: 30px;
      margin-bottom: 0;
    }

    .technology-item_icon {
      margin-right: 20px;
      min-width: 90px;
    }

    .technology-item_title {
      padding-bottom: 0.3rem;
      margin-bottom: 0.5rem;
    }
  }
}
