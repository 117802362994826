.project-item {
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0px 4px 13px rgba(20, 28, 60, 0.08);
  margin-bottom: 30px;
  height: 670px;
  p {
    color: #6c6c6c;
    letter-spacing: -0.015em;
    line-height: 1.4;
    margin-bottom: 1.7rem;
  }
  .project-item_img {
    height: 273px;
    position: relative;
    a {
      display: block;
      border-bottom: none;
      height: 100%;
    }
    img {
      display: block;
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
  .project-item_desc {
    padding: 1.8rem 1.6rem;
    flex: 1 0 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    background: white;
  }
  .ellipsis {
    max-height: 260px;
    position: relative;
    overflow: hidden;
    line-height: 1.5em;
  }
  .ellipsis::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 22px; /* Высота затухания */
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0) 0%,
      white 100%
    );
  }
  .project-item_title {
    display: block;
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 0.7rem;
    a {
      color: black;
      border-bottom: none;
      transition: color 0.3s;
    }
  }

  .article-item_tags {
    margin: 0 -5px 2.5rem;
    display: flex;
    flex-wrap: wrap;
    font-size: 0.875rem;
    margin-bottom: 0.3rem;
    // li {
    //   padding: 0 5px;
    //   margin-bottom: 10px;
    // }

    .item-tag {
      display: flex;
      align-items: center;
      height: 29px;
      background: #fff2e7;
      border-radius: 100px;
      padding: 0.3rem 0.7rem;
      color: #ff8008;
      cursor: pointer;
    }

    .active {
      background: linear-gradient(241.56deg, #ff8008 100% #ffc837 100%);
      border-radius: 10px;
      border: 2px solid transparent;
      border-radius: 10px;
      background: linear-gradient(to right, white, white),
        linear-gradient(#ff8008 -0.07%, #ffc837 64.47%);
      background-clip: padding-box, border-box;
      background-origin: padding-box, border-box;
    }
  }
  .setup_wrapper-btn {
    display: flex;
    justify-content: flex-end;
    margin-top: auto;
    width: 100%;

    .setup_item-btn {
      // position: absolute;
      // bottom: 50px;
      // right: 60px;
      font-size: 0.875rem;
      font-weight: 700;
      color: #323232;
      display: flex;
      align-items: center;
      letter-spacing: 0.025em;
      text-transform: uppercase;
    }

    .setup_item-btn .ico-arrow {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      flex: 60px 0 0;
      width: 60px;
      height: 60px;
      border-radius: 50%;
      color: #000;
      font-size: 1.1rem;
      border: 1px solid #848484;
      margin-left: 1.2rem;
      border-radius: 50%;
      z-index: 1;
      .ico-arrow:after {
        content: "";
        display: block;
      }

      &:hover .ico-arrow:after {
        transform: scale(1);
      }

      .ico-arrow:after {
        height: 39px;
        width: 39px;
        border-radius: 50%;
        background: linear-gradient(241.56deg, #ff8008 -0.07%, #ffc837 64.47%);
        position: absolute;
        top: -2px;
        right: -2px;
        transform: scale(0);
        transform-origin: 50% 50%;
        transition: transform 0.3s;
        z-index: 1000;
      }
    }
  }
  @media (max-width: 991px) {
    .project-item {
      height: auto;
    }
  }
  @media (max-width: 575px) {
    .project-item_desc {
      p {
        -webkit-line-clamp: 8; /* Число отображаемых строк */
        display: -webkit-box; /* Включаем флексбоксы */
        -webkit-box-orient: vertical; /* Вертикальная ориентация */
        overflow: hidden;
      }
    }
  }
  @media (max-width: 360px) {
    .article-item_tags {
      .item-tag {
        height: fit-content;
        font-size: 0.7rem;
      }
    }
  }
}
