.fade-animation-enter {
  opacity: 0.01;

  &.fade-animation-enter-active {
    opacity: 1;
    transition: opacity 2000ms ease-in;
  }
}

.preloader {
  display: flex;
  width: 100%;
  height: 100vh;
  background: var(--bg-color);
  position: fixed;
  z-index: 14555666;
}

.preloader__image_animate {
  width: 200px;
  transition: 1s linear;
  animation: up-down 1s infinite;
  margin: auto;
}
