.tooltip-header {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 20px;
  padding-top: 24px;

  .round {
    border-radius: 100px;
    background: #FF8008;
    width: 8px;
    height: 8px;
  }

  .bg-gradient {
    background: linear-gradient(0deg, #FE8107 0%, rgba(252, 146, 5, 0.50) 24.06%, rgba(247, 178, 0, 0.00) 65.24%);
    border-radius: 0px 0px 20px 20px;
    position: relative;

    &::before {
      content: url(../../../public/img/corner.svg);
      position: absolute;
      left: -37px;
      top: 0;
    }

    &::after {
      content: url(../../../public/img/corner.svg);
      position: absolute;
      right: -37px;
      top: -3px;
      transform: rotate(-90deg);
    }
  }

  .list-items {
    border-radius: 0px 0px 20px 20px;
    background: #151515;
    display: flex;
    flex-direction: column;
    padding: 0px 20px 20px;
    margin: 0 1px 1px;

    .item {
      color: #FFF;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.24px;
      padding: 10px 30px;
      text-align: center;

      &::first-letter {
        text-transform: uppercase;
      }

      &:hover {
        border-radius: 10px;
        background: #fdfdfd33;
        color: #FF8008;
        text-decoration-line: underline;
      }
    }
  }

  &.white {
    .bg-gradient {
      &::before {
        content: url(../../../public/img/corner-w.svg);
      }

      &::after {
        content: url(../../../public/img/corner-w.svg);
      }
    }

    .list-items {
      background: #fff;

      .item {
        color: #141414;

        &:hover {
          background: rgba(255, 200, 55, 0.20);
          color: #FF8008;
        }
      }
    }
  }
}