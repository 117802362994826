.base-section.stack {
  .title {
    width: 100%;
  }

  .list-stack {
    margin-top: 40px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
  }

  @media(max-width: 980px) {
    .list-stack {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  @media(max-width: 768px) {
    .list-stack {
      margin-top: 24px;
      grid-template-columns: repeat(1, 1fr);
      gap: 16px;
    }
  }
}