.project-section {
  margin-top: 140px;
  .title {
    text-align: start;
    color: #141414;
    font-size: 48px;
    font-weight: 700;
    line-height: 1.25;
  }
  .list-portfolio {
    margin-top: 40px;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 30px;

    .project-item-wrappper {
      grid-column: span 3;

      &:nth-child(n + 3) {
        grid-column: span 2;
      }

      .project-item_desc {
        p {
          line-clamp: 3;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          display: -webkit-box;
          overflow: hidden;
          text-align: left;
        }
      }
    }
  }
  .container {
    display: flex;
    flex-direction: column;
    a {
      align-self: center;
    }
    .btn-action {
      cursor: pointer;
      border-radius: 100px;
      background: transparent;
      border: 2px solid #ff8008;
      display: inline-flex;
      padding: 20px 40px;
      justify-content: center;
      align-items: center;
      color: #ff8008;
      font-size: 14px;
      font-weight: 700;
      line-height: 1.5;
      letter-spacing: 0.4px;
      margin-top: 30px;
      align-self: center;
      text-transform: uppercase;

      &:hover {
        background: linear-gradient(
          90deg,
          #ff8008 0%,
          #fd8c06 33.33%,
          #fb9904 66.67%,
          #f8b300 100%
        );
        color: #ffffff;
      }

      .icon-arrow {
        margin-left: 10px;
        margin-top: 0px;
      }
    }
  }

  @media (max-width: 1200px) {
    margin-top: 70px;
    .list-portfolio {
      margin-top: 24px;
    }
    .title {
      font-size: 38px;
    }
  }

  @media (max-width: 991px) {
    .list-portfolio {
      grid-template-columns: repeat(2, 1fr);
      .project-item-wrappper {
        grid-column: span 1;

        &:nth-child(n + 3) {
          grid-column: span 1;
        }
      }
    }
  }

  @media (max-width: 768px) {
    .list-portfolio {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  @media (max-width: 580px) {
    margin-top: 40px;
    .list-portfolio {
      gap: 16px;
    }

    .title {
      font-size: 32px;
    }

    .btn-action {
      margin-top: 32px;
      width: 100%;
      border-radius: 12px;
      padding: 14px 24px;
      font-size: 16px;
      font-weight: 600;
    }
  }
}
