.not-found-page-wrapper {
  font-size: 22px;
  color: #007ec5;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  width: 100%;
  margin-top: 100px;
  min-height: 100vh;
  background-color: #151515;
  .not-found-back {
    background-color: #151515;
    background: linear-gradient(#151515 98%, #ff8008 100%);
    box-shadow: 0px 103px 184px 0px #ff8008;
    height: 100%;
    margin-bottom: -60px;
    padding-bottom: -60px;
    .not-found-content-wrapper {
      background-image: url("../../../public/img/404.svg");
      background-repeat: no-repeat;

      background-position: 0% 15%;
      .not-found-status {
        font-size: 40px;
        padding-top: 90px;
        text-align: start;
        .not-found-status-404 {
          font-size: 64px;
          color: #ff8008;
          font-weight: 800;
        }
        .not-found-status-text {
          font-size: 24px;
          color: #ff8008;
          font-weight: 800;
          margin-top: 22px;
        }
      }

      .wrapper-symbol-mobile {
        display: none;
      }
      .not-found-description {
        font-size: 22px;
        display: flex;
        flex-direction: row;
        margin-top: 200px;

        gap: 20px;

        .not-found-info {
          .not-found-status-404 {
            font-size: 32px;
            font-weight: 700;
            line-height: 40px;
            text-align: left;
            color: #ffffff;
          }
          .row-block {
            display: flex;
            flex-direction: row;
          }
          .not-found-status-text {
            font-family: Montserrat;
            font-size: 20px;
            font-weight: 600;
            line-height: 32px;
            text-align: left;
            color: #ffffff;
            .link {
              font-family: Montserrat;
              font-size: 20px;
              font-weight: 400;
              text-decoration: underline;
              line-height: 32px;
              text-align: left;
              color: #fd8a06;
            }
          }
          .social-link {
            display: flex;
            flex-direction: row;
          }
        }
        .wrapper-symbol {
          max-width: 40%;
          position: relative;
          display: flex;
          margin-bottom: 90px;
          img {
            width: inherit;
            object-fit: contain;
            max-width: 430px;
            height: auto;
            z-index: 20;
          }
        }
      }
    }
    @media (max-width: 768px) {
      .not-found-content-wrapper {
        background-image: url("../../../public/img/404.svg");
        background-repeat: no-repeat;
        background-position: 0% 15%;
        background-size: contain;
        .not-found-status {
          padding-top: 24px;
          text-align: start;
          .not-found-status-404 {
            font-size: 36px;
            color: #ff8008;
            font-weight: 800;
          }
          .not-found-status-text {
            font-size: 20px;
            color: #ff8008;
            font-weight: 400;
          }
        }
        .wrapper-symbol-mobile {
          display: block;
          height: 280px;
          img {
            height: 280px;
          }
        }
        .not-found-description {
          display: flex;
          flex-direction: row;
          margin-top: 40px;

          gap: 20px;

          .not-found-info {
            .not-found-status-404 {
              font-size: 24px;
              font-weight: 700;
              line-height: 40px;
              text-align: left;
              color: #ffffff;
            }
            .social-link {
              display: flex;
              flex-direction: column;
            }
            .not-found-status-text {
              font-family: Montserrat;
              font-size: 16px;
              font-weight: 600;
              line-height: 24px;
              text-align: left;
              color: #ffffff;
              .link {
                font-family: Montserrat;
                font-size: 16px;
                font-weight: 400;
                line-height: 32px;
                text-align: left;
                color: #fd8a06;
              }
            }
          }
          .wrapper-symbol {
            display: none;
          }
        }
      }
    }
    @media (max-width: 580px) {
    }
  }
  .footer {
    .base-section {
      box-shadow: 0px 103px 184px 0px #ff8008;
      margin-top: 0;
    }
  }
}
