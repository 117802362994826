.comment-project_item {
  margin-bottom: 3.8rem;
  display: flex;
  &:last-child {
    margin-bottom: 0;
  }
  .comment-project_img {
    flex: 190px 0 0;
    img {
      border-radius: 13px;
      width: 100%;
    }
  }

  .comment-project_desc {
    flex: 1 0 0;
    padding-left: 1.7rem;
  }
  .h4 {
    margin-bottom: 0.3rem;
  }

  .comment-project_name {
    display: block;
    font-size: 0.875rem;
    font-weight: 500;
    color: #909090;
    margin-bottom: 2.5rem;
  }

  @media (max-width: 991px) {
    display: block;
    .comment-project_img {
      width: 150px;
      width: 100px;
      margin-right: 1rem;
      float: left;
    }
  }
}
