@media (min-width: 992px) {
  .navbar .navbar-nav .nav-link {
    padding-top: 1.875rem;
    padding-bottom: 1.875rem;
  }

  .navbar-sticky-on .navbar-nav .nav-link {
    padding-top: 1.625rem;
    padding-bottom: 1.625rem;
  }
}

@media (max-width: 991px) {
  .navbar {
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;

    &.active {
      display: block;

      .navbar-inner {
        right: 0;
      }
    }

    .navbar-inner {
      right: -100%;
      -webkit-transition: right 0.3s 0.2s;
      transition: right 0.3s 0.2s;
    }

    .nav-link {
      border-bottom: 1px solid #eee;
      padding-top: 0.75rem;
      padding-bottom: 0.75rem;
    }

    &.navbar-dark .nav-link {
      color: #54565a;

      &:hover,
      &:focus,
      &.active,
      &.current-menu-item {
        color: #2c2e30;
      }
    }
  }

  .navbar-inner {
    position: fixed;
    top: 0;
    bottom: 0;
    width: 20rem;
    padding-top: 2.5rem;
    background-color: #fff;
    -webkit-box-shadow: 0 0.5rem 1.875rem rgba(0, 0, 0, 0.15);
    box-shadow: 0 0.5rem 1.875rem rgba(0, 0, 0, 0.15);
    border-radius: 0;
    overflow-y: auto;
    z-index: 1050;
  }
}

.main-header {
  .container {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .navbar-inner {
    display: flex;
    width: calc(100% - 120px);
    justify-content: space-between;
    align-items: center;

    .list-project {
      display: none;
      flex-direction: column;
      gap: 10px;

      .nav-item:first-child:hover {
        color: #141414;
        cursor: default;
      }

      .nav-item:not(:first-child) {
        padding-left: 10px;

        &::first-letter {
          text-transform: uppercase;
        }
      }
    }

    .nav-item {
      color: #fff;
      font-size: 14px;
      font-weight: 500;
      line-height: 1.43;
      letter-spacing: 0.21px;
      cursor: pointer;
      position: relative;
    }

    .active {
      text-decoration: underline;
      text-decoration-color: #ff8008;
      text-underline-offset: 10px;
    }

    .nav-item:hover {
      color: #ff8008;
    }

    .navigation {
      display: flex;
      gap: 5px 25px;

      .nav-item {
        line-height: 1.43;
        letter-spacing: 0.21px;
      }
      .active {
        text-decoration: underline;
        text-decoration-color: #ff8008;
        text-underline-offset: 10px;
      }
    }

    .block-btn,
    .block-social {
      display: flex;
      gap: 20px;
      align-items: center;
    }

    .lang {
      text-transform: uppercase;
    }
  }

  &.navbar-sticky-moved-up {
    .navbar-inner {
      .nav-item {
        color: #141414;

        &:hover {
          color: #ff8008;
        }
      }

      .block-btn {
        .link-messenger {
          span {
            color: #141414;
          }
          &:hover {
            color: #141414;
          }
        }
      }
    }
  }
  &.black-header {
    background-color: #151515;
    height: 112px;
    .navbar-brand-regular {
      display: flex;
    }

    .navbar-brand-sticky {
      display: none;
    }

    .navbar-inner {
      .nav-item {
        color: #fff;
      }
    }
  }
  &.white-header {
    background: #fff;
    .navbar-inner {
      .nav-item {
        color: var(--black-color);
      }
    }
    .navbar-toggler-icon {
      background: #7e8085;
      &::after,
      &::before {
        background: #7e8085;
      }
    }
    .display-none {
      display: none;
    }
    .display-block {
      display: block;
    }
  }

  @media (min-width: 1230px) {
    .navbar-inner {
      .navigation {
        margin-left: 90px;
      }
    }
  }

  @media (max-width: 1240px) {
    .navbar-inner {
      .block-btn {
        .link-messenger {
          width: 40px;
          height: 40px;
          border-radius: 50%;

          img {
            width: 20px;
            height: 20px;
          }

          span {
            display: none;
          }
        }
      }
    }
  }

  @media (max-width: 991px) {
    .container {
      padding-top: 4px;
      padding-bottom: 4px;
    }

    .navbar-inner {
      padding: 30px;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      max-width: 300px;
      min-width: 300px;
      width: 100%;

      .nav-item {
        color: #141414;
      }

      .navigation {
        flex-direction: column;
        gap: 20px;

        .nav-item {
          &:first-child {
            display: none;
          }
        }

        .list-project {
          display: flex;

          .nav-item {
            &:first-child {
              display: block;
            }
          }
        }
      }

      .lang {
        order: 1;
      }

      .block-social {
        order: 2;
      }

      .block-btn {
        flex-direction: column;
        align-items: start;
        .lang {
          margin-top: 0px;
        }
      }

      .block-btn,
      .lang {
        margin-top: 20px;
      }
    }
  }

  &.shadow-bottom {
    box-shadow: 0px -61px 184px 0px #ff8008;
  }

  &.notification-header {
    height: 112px;

    .lang {
      margin-left: auto;
    }

    @media (max-width: 580px) {
      height: 80px;
      padding-top: 0;
      padding-bottom: 0;

      .container {
        height: inherit;
        padding-top: 0;

        .navbar-inner {
          position: static;
          padding: 0;
          box-shadow: none;
          width: 60px;
          max-width: 60px;
          min-width: 60px;

          a {
            margin-top: 0;
          }
        }
      }

      .navbar-brand {
        .brand-logo {
          width: 72px;
          height: 72px;
        }
      }
    }
    &.black-header {
      background-color: #151515;
      height: 112px;
      .navbar-brand-regular {
        display: flex;
      }
      .navbar-inner {
        background-color: #151515;
      }
      .navbar-brand-sticky {
        display: none;
      }

      .navbar-inner {
        .nav-item {
          color: #fff;
        }
      }
    }
  }
}
