.stack-item {
  width: 100%;
  color: #141414;
  border-radius: 20px;
  background-color: #F5F5F5;
  position: relative;
  padding: 24px 20px 20px;
  min-height: 185px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: space-between;
  text-align: start;
  overflow: hidden;

  .name {
    font-size: 20px;
    font-weight: 700;
    line-height: 1.2;
    width: calc(100% - 100px);
  }

  .description {
    font-size: 16px;
    font-weight: 600;
    line-height: 1.5;
    letter-spacing: 0.24px;
  }

  .icon-stack {
    border: 23px solid #fff;
    width: 105px;
    height: 105px;
    position: absolute;
    border-radius: 50%;
    top: -23px;
    right: -23px;
  }


  @media(max-width: 768px) {
    padding: 16px;
    min-height: 128px;

    .name {
      font-size: 18px;
      line-height: 1.33;
    }

    .description {
      font-size: 14px;
      font-weight: 500;
      line-height: 1.43;
    }

    .icon-stack {
      border-width: 20px;
      width: 96px;
      height: 96px;
      top: -20px;
      right: -20px;
    }
  }
}