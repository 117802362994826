.list-network {
  display: flex;
  gap: 40px;

  svg {
    &:hover {
      path:first-child {
        fill: #ff8008 !important;
      }
    }
  }

  img {
    margin-top: 0px;
  }

  @media (max-width: 580px) {
    gap: 12px;
  }
}
