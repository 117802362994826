.bg-services {
  .container {
    display: flex;
    flex-direction: column;
    height: inherit;
  }

  .wrapper-content {
    margin-top: 170px;
    padding-bottom: 130px;
    display: flex;
    flex-direction: column;
    gap: 30px;

    .title {
      text-align: start;
      background-image: linear-gradient(
        to right,
        #ff8008,
        #fd8c06,
        #fb9904,
        #f8b300
      );
      -webkit-background-clip: text;
      background-clip: text;
      color: transparent;
      font-size: 64px;
      font-weight: 800;
      line-height: 1.25;
      letter-spacing: 1.28px;
    }

    .text {
      max-width: 880px;
      font-weight: 500;
      font-size: 24px;
      line-height: 140%;
      letter-spacing: -0.25px;
      text-align: start;
    }

    .btn-action {
      cursor: pointer;
      border-radius: 100px;
      background: linear-gradient(
        90deg,
        #ff8008 0%,
        #fd8c06 33.33%,
        #fb9904 66.67%,
        #f8b300 100%
      );
      display: inline-flex;
      padding: 20px 40px;
      justify-content: center;
      align-items: center;
      color: #fff;
      font-size: 18px;
      font-weight: 700;
      line-height: 1.5;
      letter-spacing: 0.4px;
      margin-top: 30px;
      align-self: flex-start;
      border: 2px solid transparent;

      &:hover {
        border: 2px solid #ff8008;
        color: #ff8008;
        background: transparent;
      }
    }

    .info-cards {
      display: flex;
      margin-top: 50px;
      gap: 64.5px;
      .card-item {
        max-width: 260px;
        .item-title {
          font-size: 74px;
          font-weight: 800;
          line-height: 80px;
          letter-spacing: 0.02;
          background: linear-gradient(#ff8008, #ffc837);
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          margin-bottom: 10px;
        }
        .item-text {
          font-weight: 500;
          font-size: 20px;
          line-height: 1.4;
          letter-spacing: 0;
          text-align: start;
          margin-bottom: 0px;
        }
      }
    }
  }

  @media (max-width: 1200px) {
    .wrapper-content {
      padding-bottom: 120px;
      gap: 80px;

      .title {
        font-size: 48px;
      }

      .info-cards {
        flex-wrap: wrap;
        gap: 95px;
      }
    }
  }
  @media (max-width: 770px) {
    .wrapper-content {
      margin-top: 150px;
      .info-cards {
        gap: 65px;
        .card-item {
          max-width: 100%;
        }
      }
    }
  }
  @media (max-width: 580px) {
    .wrapper-content {
      padding-bottom: 48px;
      gap: 35px;

      .title {
        font-size: 36px;
        line-height: 1.11;
      }
      .btn-action {
        font-size: 14px;
      }
    }
  }
}
