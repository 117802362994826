.media-item {
  display: flex;
  flex-direction: column;
  gap: 20px;
  color: #141414;
  padding-bottom: 30px;
  border-bottom: 1px solid #838588;

  .title {
    font-size: 32px;
    font-weight: 700;
    line-height: 1.25;
  }

  .wrapper-content {
    display: flex;
    gap: 40px;
    align-items: center;
    justify-content: space-between;

    .description {
      color: #505050;
      font-size: 20px;
      font-weight: 500;
      line-height: 1.2;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      max-width: 730px;
    }

    .link-media {
      flex-shrink: 0;
      align-self: flex-end;
      display: flex;
      align-items: center;
      gap: 20px;

      &:hover {
        color: #ff8008;
      }

      &:visited {
        color: inherit;
      }

      span {
        font-size: 14px;
        font-weight: 700;
        line-height: 1.5;
        letter-spacing: 0.35px;
        text-transform: uppercase;
      }

      .arrow-span {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        flex: 60px 0 0;
        width: 60px;
        height: 60px;
      }

      .arrow-span:after {
        content: "";
        display: block;
      }

      &:hover .arrow-span:after {
        transform: scale(1);
      }
      .arrow-span:after {
        height: 39px;
        width: 39px;
        border-radius: 50%;
        background: linear-gradient(241.56deg, #ff8008 -0.07%, #ffc837 64.47%);
        position: absolute;
        top: -2px;
        right: -2px;
        transform: scale(0);
        transform-origin: 50% 50%;
        transition: transform 0.3s;
        z-index: 99000;
      }

      .arrow {
        width: 60px;
        height: 60px;
        transform: rotate(-45deg);
      }
    }
  }

  @media (max-width: 1200px) {
    padding-bottom: 24px;
    gap: 16px;

    .title {
      font-size: 26px;
    }

    .wrapper-content {
      .description {
        font-size: 18px;
      }

      .link-media {
        gap: 10px;
        .arrow {
          width: 48px;
          height: 48px;
        }
      }
    }
  }

  @media (max-width: 768px) {
    padding-bottom: 16px;
    gap: 12px;

    .title {
      font-size: 20px;
      line-height: 1.6;
    }

    .wrapper-content {
      flex-direction: column;
      gap: 16px;

      .description {
        width: 100%;
        font-size: 18px;
        line-height: 1.5;
        -webkit-line-clamp: 6;
      }

      .link-media {
        gap: 20px;
        .arrow {
          transform: rotate(0deg);
        }
      }
    }
  }
}
