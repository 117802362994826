.wrapper-checkbox {
  user-select: none;
  display: inline-flex;
  position: relative;

  label {
    display: flex;
    align-items: flex-start;
    cursor: pointer;
    color: #505050;
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 0;
    text-align: start;

    &::before {
      content: "";
      min-width: 18px;
      height: 18px;
      border: 2px solid #000;
      border-radius: 4px;
      margin-right: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  input[type="checkbox"] {
    position: absolute;
    z-index: -1;
    opacity: 0;
  }

  .custom-checkbox:checked + label::before {
    content: url(../../../public/img/check.svg);
    border-color: #ffa910;
    background-color: #ffa910;
  }
}
