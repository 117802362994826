.wrapper-field {
  position: relative;
  width: 100%;

  .field {
    border-radius: 8px;
    background: #F5F5F5;
    padding: 12px;
    color: #141414;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    width: 100%;
    outline: none;
    border: none;

    &:focus {
      box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    }
  }

  textarea {
    min-height: 100px;
  }

  .label {
    position: absolute;
    top: 16px;
    left: 12px;
    color: #505050;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 400;
    line-height: 1;
    cursor: text;

    span:not(:first-child) {
      color: #FF0808;
    }
  }

  .field:focus+.label {
    opacity: 0;
  }

  &.not-valid {
    border-radius: 8px;
    border: 1px solid #FF0808;

    .field {
      padding: 11px;
    }
  }
}