.base-section.team {
  .title {
    width: 100%;
  }

  .team-block {
    margin-top: 40px;
    display: flex;
    gap: 30px;
    margin-bottom: 30px;
  }

  @media (max-width: 1000px) {
    .team-block {
      margin-top: 24px;
      gap: 16px;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
    }
  }

  @media (max-width: 768px) {
    .team-block {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  @media (max-width: 580px) {
    .team-block {
      gap: 12px;
      display: flex;
      flex-wrap: nowrap;
      overflow-x: scroll;
      scrollbar-color: transparent transparent;
      scrollbar-width: none;
      scroll-behavior: smooth;
      scroll-snap-type: x mandatory;

      &::-webkit-scrollbar {
        width: 0;
        height: 0;
      }
    }
  }
}
