#contact-block-new {
  background: var(--black-color);
  color: white;
  background: url("../../../public/img/contact-bg-new.webp") center no-repeat;
  background-size: cover;
  border-radius: 50px;

  &.contact-block {
    border-radius: 50px 50px 0px 0px;
    padding-bottom: 100px !important;
  }
  .contact-wrap-new {
    text-align: left;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 60px;
    a {
      color: black;
      display: flex;
      height: 35px;
      align-items: center;
      margin-top: 5px;
      margin-bottom: 5px;
    }

    a:hover {
      color: var(--bg-color);
    }

    img {
      margin-right: 10px;
    }
  }

  h2 {
    color: white;
  }

  .contact-block-wrap-new {
    width: 32%;
    color: black;
    height: 313px;
    background: white;
    border-radius: 24px;
    border: 2px var(--bg-color) solid;
    text-align: left;
    padding: 20px;
    margin-top: 20px;
    a {
      color: black;
    }
  }

  .contact-block-title {
    font-weight: 700;
    font-size: 18px;
    line-height: 150%;
    margin-bottom: 30px;
  }

  .contact-block-text {
    font-weight: 600;
    font-size: 18px;
    line-height: 150%;
  }
  @media (max-width: 1080px) {
    #contact-block-new {
      background-size: cover;
    }
  }
  @media (max-width: 991px) {
    .contact-block-wrap-border {
      width: 90%;
    }

    .contact-block-wrap-new {
      width: 100%;
    }
  }
}
