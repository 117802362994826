.consultation-section {
  margin-top: 70px;
  .container {
    position: relative;
  }

  .title {
    font-weight: 700;
    font-size: 48px;
    line-height: 1.3;
  }

  .subtitle-wrapper {
    .subtitle {
      font-weight: 600;
      font-size: 20px;
      line-height: 1.5;
      margin: 20px 0px;
    }
    .subtitle-list {
      display: flex;
      flex-direction: column;
      gap: 16px;

      .list-item {
        display: flex;
        gap: 12px;
        align-items: center;
        .item-text {
          font-weight: 500;
          font-size: 18px;
          line-height: 1.5;
          color: #323232;
          text-align: left;
        }
      }
    }
  }

  .form {
    width: 100%;
    margin: 50px 0px 100px;
    padding: 40px;
    border-radius: 20px;
    border: 2px solid #ffffff;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 20px;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 30px;
    background-color: #fff;
    z-index: 2;

    .form-bg {
      position: absolute;
      top: 0;
      right: 0;
      max-width: 450px;
    }

    .form-title {
      font-size: 24px;
      font-weight: 600;
      color: #141414;
      max-width: 600px;
      text-align: left;
      line-height: 30px;
    }

    .block-fields {
      display: flex;
      flex-direction: column;
      max-width: 600px;
      gap: 30px;
    }

    .checkboxes {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }

    .btn-action {
      cursor: pointer;
      border-radius: 100px;
      background: linear-gradient(
        90deg,
        #ff8008 0%,
        #fd8c06 33.33%,
        #fb9904 66.67%,
        #f8b300 100%
      );
      display: inline-flex;
      padding: 20px 40px;
      justify-content: center;
      align-items: center;
      color: #fff;
      font-size: 18px;
      font-weight: 700;
      line-height: 1.5;
      letter-spacing: 0.4px;
      margin-top: 10px;
      align-self: flex-start;
      border: 2px solid transparent;

      &:hover {
        border: 2px solid #ff8008;
        color: #ff8008;
        background: transparent;
      }
    }

    .error-message {
      position: absolute;
      left: 0;
      bottom: -15px;
      font-size: 12px;
      font-weight: 400;
      line-height: 1;
      color: #ff0808;
    }
  }

  @media (max-width: 1200px) {
    .title {
      font-size: 38px;
    }
  }

  @media (max-width: 992px) {
    .form {
      gap: 24px;
      margin-top: 24px;

      .block-fields {
        gap: 24px;
      }
    }
  }

  @media (max-width: 568px) {
    .title {
      font-size: 32px;
    }

    .form {
      gap: 20px;
      padding: 20px 16px;

      .block-fields {
        gap: 20px;
        flex-direction: column;
      }
    }
  }
}
