// @media screen and (max-width: 992px) {
.img-btn {
  .get-btn {
    height: 48px;
    padding: 12px 24px 12px 24px;
    border-radius: 10px;
    border: linear-gradient(
        90deg,
        #ff8008 0%,
        #fd8c06 33.33%,
        #fb9904 66.67%,
        #f8b300 100%
      ),
      linear-gradient(0deg, #141414, #141414);
    border: 2px solid var(--bg-color);
    color: var(--bg-color);
    margin-bottom: 16px;
    width: 100%;
    vertical-align: middle;
    font-weight: 600;

    &:hover {
      background-color: var(--black-color);
      color: var(--bg-color);
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border: 1px solid var(--bg-color);
    }
  }

  .get-btn-img {
    margin-right: 16px;
    margin-top: 0px;
  }
  @media (max-width: 360px) {
    .get-btn {
      padding: 12px 4px 12px 4px;
      .get-btn-img {
        margin-right: 6px;
        width: 14px;
        height: 14px;
        margin-top: 0px;
      }
    }
  }
}
