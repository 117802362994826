.results-section {
  margin-top: 130px;
  color: #141414;

  .title {
    text-align: start;
    font-size: 48px;
    font-weight: 700;
    line-height: 1.25;
  }
  .description-wrap {
    margin: 30px 0px 10px;
    max-width: 955px;
    display: flex;
    flex-direction: column;
    gap: 10px;

    .description {
      font-weight: 500;
      font-size: 20px;
      line-height: 1.5;
      letter-spacing: -0.5px;
      text-align: left;
      margin: 0px;
    }
  }

  .results-accordion {
    .accordion-item {
      display: flex;
      justify-content: space-between;
      padding: 30px 0px;
      border-bottom: 1px solid #323232;
      cursor: pointer;

      .item-wrap {
        display: flex;
        gap: 80px;

        .item-number {
          font-weight: 700;
          font-size: 20px;
          line-height: 1.5;
          letter-spacing: 0.5px;
          width: 42px;
          height: 42px;

          &.active {
            color: #ffffff;
            background: linear-gradient(to bottom right, #ff8008, #f8b300);
            border-radius: 90px;
            padding: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }

        .item-title-wrap {
          max-width: 520px;
          text-align: left;

          .item-title {
            font-weight: 600;
            font-size: 24px;
            line-height: 1.4;

            .title__orange {
              font-weight: 800;
              background: linear-gradient(#ff8008, #ffc837);
              background-clip: text;
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
            }
          }

          .item-text {
            margin-top: 20px;
            font-weight: 400;
            font-size: 16px;
            line-height: 1.5;
            letter-spacing: -0.25px;
            color: #323232;
          }
        }
      }
      .item-img {
        width: 385px;
        max-height: 185px;
        object-fit: cover;
        border-radius: 20px;
        border-radius: 2px solid #fff;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 20px;

        &.img-margin {
          margin-top: 10px;
        }
      }
      .icon-open {
        cursor: pointer;
      }
    }
  }
  @media (max-width: 1200px) {
    margin-top: 70px;
    .title {
      font-size: 38px;
    }
    .results-accordion {
      .accordion-item {
        .item-wrap {
          gap: 30px;
        }
      }
    }
  }

  @media (max-width: 768px) {
    .results-accordion {
      .accordion-item {
        .item-wrap {
          .item-number {
            width: 24px;
            height: 24px;
            &.active {
              margin-top: 3px;
            }
          }
        }
      }
    }
  }

  @media (max-width: 580px) {
    margin-top: 40px;

    .title {
      font-size: 32px;
    }

    .results-accordion {
      .accordion-item {
        .item-wrap {
          gap: 5px;

          .item-title-wrap {
            .item-title {
              font-size: 20px;
            }

            .item-text {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}
