.wrapper-upload-block {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;

  .block-upload {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    gap: 30px;

    .label-upload {
      margin-bottom: 0;
      height: 48px;
      cursor: pointer;
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
      gap: 5px;
      border-radius: 10px;
      border: 1px solid #141414;

      &:hover {
        background: rgb(50, 50, 50, 0.06);
      }

      img {
        width: 24px;
        height: 24px;
      }
      .file {
        height: 60px;
      }
      span {
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        color: #141414;
      }

      input {
        opacity: 0;
        z-index: -1;
        width: 0;
      }
    }

    .name-file {
      text-align: start;
      width: 100%;
      color: #adadad;
      font-size: 16px;
      font-weight: 400;
      line-height: normal;

      &.selected {
        color: #ffa910;
      }
    }
  }

  .info-message {
    display: flex;
    gap: 10px;
    align-items: flex-start;
    color: #505050;
    font-size: 12px;
    font-weight: 500;
    line-height: 1.5;
    letter-spacing: 0.12px;

    img {
      width: 16px;
      height: 16px;
    }
  }

  .error-mes {
    font-size: 12px;
    font-weight: 400;
    line-height: 1;
    color: #ff0808;
  }

  @media (max-width: 992px) {
    .block-upload {
      gap: 24px;

      .label-upload {
        img {
          width: 20px;
          height: 20px;
        }

        span {
          font-size: 14px;
        }
      }
    }
  }
  @media (max-width: 768px) {
    .file {
      width: 300px;
    }
  }

  @media (max-width: 568px) {
    gap: 8px;

    .block-upload {
      gap: 8px;
      grid-template-columns: 1fr;

      .label-upload {
        padding: 8px 24px;
        height: 33px;
        border-color: #707070;

        img {
          width: 16px;
          height: 16px;
        }

        span {
          font-weight: 500;
        }
      }

      .name-file {
        font-size: 14px;
      }
    }
  }
  @media (max-width: 360px) {
    .block-upload {
      .label-upload {
        width: 254px;
        max-width: 254px;
      }
    }
  }
}
