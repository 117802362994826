.review-item {
  display: block !important;
  position: relative;
  background: #f1f3f6;
  border-radius: 20px;
  padding: 1.7rem;
  padding-top: 2rem;
  height: 100%;
  margin-top: 50px;
  .quotes {
    position: absolute;
    top: -93px;
    left: 1.7rem;
    font-family: "Dela Gothic One";
    font-size: 200px;
    line-height: 200px;
    display: flex;
    align-items: center;
    background: linear-gradient(
      90deg,
      #ff8008 0%,
      #fd8c06 33.33%,
      #fb9904 66.67%,
      #f8b300 100%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
  }

  .review-item_header {
    display: block;
    margin-bottom: 0.8rem;
    text-align: right;
  }

  .review-item_header img {
    width: 41px;
    display: inline-block;
  }

  .review-item_body {
    display: flex;
  }

  .review-item_mod-img {
    flex: 160px 0 0;
  }

  .review-item_mod-img img {
    border-radius: 4px;
    width: 100%;
  }
  .review-item_img {
    flex: 34% 0 0;
  }

  .review-item_img img {
    width: 100%;
  }

  .review-item_desc {
    flex: 1 0 0;
    padding-left: 2rem;
    font-size: 0.875rem;
    line-height: 1.5;
  }

  .review-item_title {
    display: block;
    font-size: 1.5rem;
    font-weight: 700;
  }

  .review-item_position {
    display: block;
    font-weight: 500;
    color: #999999;
    margin-bottom: 1rem;
  }
  @media (max-width: 991px) {
    .review-item {
      padding-left: 1rem;
      padding-right: 1rem;
    }

    .review-item_img {
      flex: 46% 0 0;
    }

    .review-item_desc {
      padding-left: 1.1rem;
    }
  }
  @media (max-width: 767px) {
    .review-item_body {
      display: block;
    }

    .review-item_img {
      float: left;
      margin-right: 1rem;
      width: 27%;
    }

    .review-item_title {
      font-size: 1.125rem;
    }
    .review-item_desc {
      padding-left: 0;
    }
    .quotes {
      top: -6rem;
      font-size: 5.5rem;
    }
  }
}
