.base-section.request {
  .container {
    position: relative;
  }

  .shadow-form {
    position: absolute;
    width: 485px;
    height: 485px;
    border-radius: 50%;
    background: linear-gradient(
      90deg,
      rgba(244, 139, 71, 0.16) 0%,
      rgba(249, 169, 63, 0.16) 50%,
      rgba(255, 200, 55, 0.16) 100%
    );
    filter: blur(71px);
    z-index: 1;
    top: -100px;
    right: -90px;

    &:last-child {
      bottom: -100px;
    }
  }

  .title {
    width: 100%;
  }

  .form {
    width: 100%;
    margin-top: 40px;
    padding: 30px;
    border-radius: 20px;
    border: 2px solid #ff8008;
    box-shadow: 0px 0px 10px 0px rgba(255, 128, 8, 0.17),
      0px 1px 3px 0px rgba(143, 79, 0, 0.11);
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 30px;
    background-color: #fff;
    z-index: 2;

    .block-fields {
      display: flex;
      gap: 30px;
    }

    .checkboxes {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }

    .btn-action {
      width: 100%;
      padding: 14px 24px;
      font-size: 16px;
      font-weight: 600;
      line-height: 20px;
      outline: none;
      border: none;
      margin-top: 10px;
      position: relative;
    }

    & + .shadow-form {
      top: 50%;
      left: -90px;
    }

    .error-message {
      position: absolute;
      left: 0;
      bottom: -15px;
      font-size: 12px;
      font-weight: 400;
      line-height: 1;
      color: #ff0808;
    }
  }

  @media (max-width: 992px) {
    .form {
      gap: 24px;
      margin-top: 24px;

      .block-fields {
        gap: 24px;
      }
    }
  }

  @media (max-width: 568px) {
    .shadow-form {
      width: 250px;
      height: 250px;
      top: -40px;
      right: 0px;
    }

    .form {
      gap: 20px;
      padding: 20px 16px;

      .block-fields {
        gap: 20px;
        flex-direction: column;
      }

      & + .shadow-form {
        top: 50%;
        left: -125px;
      }
    }
  }
}
