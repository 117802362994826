.base-modal {

  .modal-header {
    .close {
      padding-top: 5px;
      padding-right: 10px;

      span {
        font-size: 2rem;
      }

      .sr-only {
        display: none;
      }
    }
  }

  .modal-title {
    font-size: 20px;
    line-height: 1.2;
    display: flex;
    gap: 8px;
    align-items: flex-start;
    text-align: start;

    img {
      height: 20px;
      width: auto;
    }
  }
}