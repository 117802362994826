.footer {
  background-color: transparent;
  z-index: 1;

  .base-section {
    border-radius: 50px 50px 0px 0px;
    background: #141414;
    padding: 60px 0;
    text-align: start;

    &.section__margin {
      margin-top: -50px;
    }

    .wrapper-logo {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      gap: 40px;

      .brand-logo {
        width: 75px;
        height: 75px;
      }

      .heading {
        color: #f5f5f5;
        font-size: 24px;
        font-weight: 700;
        line-height: 1.3;
        margin-top: 40px;
      }
    }

    .wrapper-info {
      margin-top: 20px;
      display: flex;
      justify-content: space-between;
      flex-wrap: nowrap;
      gap: 30px;

      .item-info {
        display: flex;
        flex-direction: column;

        &:first-child {
          gap: 30px;
        }

        &:last-child {
          gap: 13px;
          justify-content: flex-end;
          align-items: flex-end;

          .list-network {
            display: none;
          }

          .link {
            font-size: 16px;
            font-weight: 600;
          }
        }
      }
    }

    .list-network {
      display: flex;
      gap: 40px;
    }

    .copyrights {
      color: #8b8b8b;
      font-size: 12px;
      font-weight: 500;
      line-height: 1.66;
    }

    .link {
      font-family: "Montserrat", sans-serif;
      color: #f5f5f5;
      font-size: 16px;
      &:hover {
        color: #ffa910;
      }
    }

    @media (max-width: 992px) {
      padding: 40px 0;
      border-radius: 40px 40px 0px 0px;

      .wrapper-logo {
        gap: 30px;

        .heading {
          font-size: 20px;
        }
      }

      .list-network {
        gap: 20px;
      }

      .wrapper-info {
        .item-info {
          &:first-child {
            gap: 20px;
          }

          &:last-child {
            gap: 8px;

            .link {
              font-size: 16px;
            }
          }
        }
      }

      .copyrights {
        max-width: 260px;
      }
    }

    @media (max-width: 568px) {
      padding: 48px 0;
      border-radius: 20px 20px 0px 0px;

      .wrapper-logo {
        flex-direction: column;
        gap: 16px;
        align-items: start;
        .brand-logo {
          width: 52px;
          height: 52px;
        }
      }

      .list-network {
        gap: 12px;
      }
    }

    @media (max-width: 520px) {
      .wrapper-info {
        flex-direction: column-reverse;
        gap: 16px;

        .item-info {
          &:first-child,
          &:last-child {
            gap: 12px;
          }

          &:first-child {
            .list-network {
              display: none;
            }
          }

          &:last-child {
            align-items: flex-start;
            justify-content: flex-start;

            .list-network {
              display: flex;
            }
          }
        }
      }
    }
  }
}
