.contact-block-wrap-new {
  width: 32%;
  color: black;
  height: 313px;
  background: white;
  border-radius: 24px;
  border: 2px var(--bg-color) solid;
  text-align: left;
  padding: 20px;
  margin-top: 20px;
  a {
    color: black;
  }
}
.contact-block-title {
  font-weight: 700;
  font-size: 18px;
  line-height: 150%;
  margin-bottom: 30px;
}
@media (max-width: 991px) {
  .contact-block-wrap-new {
    width: 100%;
  }
}
