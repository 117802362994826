.employee {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex: 1 0 0;
  transition: flex 0.3s;

  @keyframes showText {
    80% {
      opacity: 1;
    }
  }

  img {
    width: 100%;
    height: 510px;
    object-fit: cover;
    border-radius: 20px;
  }

  .short-info {
    display: flex;
    flex-direction: column;
    gap: 5px;
    opacity: 0;
    height: 70px;
  }

  &.active {
    flex: 4 0 0;

    img {
      background: radial-gradient(
        circle farthest-corner at 73% -13%,
        var(--bg-color) -30%,
        #838588 56%
      );
      cursor: pointer;
    }

    .short-info {
      opacity: 1;

      .name {
        color: #141414;
        font-size: 32px;
        font-weight: 700;
        line-height: 1.25;
      }

      .position {
        color: #ff8008;
        font-size: 18px;
        font-weight: 500;
        line-height: 1.2;
      }
    }
  }

  @media (max-width: 1000px) {
    img {
      height: 250px;
      background: radial-gradient(
        circle farthest-corner at 73% -13%,
        var(--bg-color) -30%,
        #838588 56%
      );
      cursor: pointer;
    }

    .short-info {
      opacity: 1;
      height: auto;

      .name {
        color: #141414;
        font-size: 20px;
        font-weight: 700;
        line-height: 1.25;
      }

      .position {
        color: #ff8008;
        font-size: 16px;
        font-weight: 500;
        line-height: 1.2;
      }
    }

    &.active {
      flex: 1 0 0;

      .short-info {
        .name {
          font-size: 20px;
        }

        .position {
          font-size: 16px;
        }
      }
    }
  }

  @media (max-width: 580px) {
    gap: 8px;
    scroll-snap-align: start;

    img {
      min-width: 316px;
      width: 316px;
      height: 316px;
    }

    .short-info {
      gap: 0;
      align-items: flex-start;
      text-align: start;
    }
  }
  @media (max-width: 360px) {
    img {
      min-width: 290px;
      width: 290px;
      height: 290px;
    }
  }
}
