.wrapper-spinner {
  display: flex;
  align-items: center;
  justify-content: center;

  .spinner {
    height: 20px;
    width: 20px;
    border-left: 2px solid transparent;
    border-bottom: 2px solid #fff;
    border-right: 2px solid #fff;
    border-top: 2px solid #fff;
    border-radius: 50%;
    animation: spinner 1.2s linear infinite;

    @keyframes spinner {
      from {
        transform: rotate(0deg);
      }

      to {
        transform: rotate(360deg);
      }
    }
  }
}