.base-section.media {
  .title {
    width: 100%;
  }

  .list-media {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    gap: 30px;
  }

  @media (max-width: 1200px) {
    .list-media {
      margin-top: 24px;
      gap: 24px;
    }
  }

  @media (max-width: 768px) {
    .list-media {
      gap: 16px;
    }
  }
}