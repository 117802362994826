.main.notification-page {
  background-color: #F5F5F5;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  text-align: start;
  overflow: hidden;

  .content-notification {
    min-height: 850px;
    position: relative;

    .shadow {
      position: absolute;
      width: 485px;
      height: 485px;
      border-radius: 50%;
      background: linear-gradient(90deg,
          rgba(244, 139, 71, 0.16) 0%,
          rgba(249, 169, 63, 0.16) 50%,
          rgba(255, 200, 55, 0.16) 100%);
      filter: blur(71px);
      z-index: 1;
      top: -220px;
      right: -245px;

      &:last-child {
        top: 80%;
        left: -245px;
      }
    }

    .title {
      margin-top: 180px;
      text-align: start;
      background-image: linear-gradient(to right, #FF8008,
          #FD8C06,
          #FB9904,
          #F8B300);
      -webkit-background-clip: text;
      background-clip: text;
      color: transparent;

      .main-title {
        font-size: 64px;
        font-weight: 800;
        line-height: 1.25;
        letter-spacing: 2px;
      }

      .subtitle {
        font-size: 24px;
        font-weight: 700;
        line-height: 40px;
      }
    }

    .wrapper-info {
      display: flex;
      gap: 20px;

      .wrapper-symbol {
        max-width: 40%;
        position: relative;
        display: flex;

        .bg-circle {
          position: absolute;
          width: 1000px;
          height: 1000px;
          background-color: #fff;
          border-radius: 50%;
          top: 170px;
          left: -210px;
        }

        img {
          width: inherit;
          object-fit: contain;
          max-width: 430px;
          height: auto;
          z-index: 20;
        }
      }
    }

    .info-text {
      margin-top: 70px;
      max-width: 60%;
      display: flex;
      flex-direction: column;
      z-index: 2;
      gap: 10px;

      .subtitle {
        color: #141414;
        font-size: 32px;
        line-height: 40px;
        font-weight: 700;
      }

      .description {
        color: #151515;
        font-size: 20px;
        line-height: 32px;
        font-weight: 600;
      }
    }

    .list-network {
      margin-top: 20px;
    }

    @media(max-width: 990px) {
      .wrapper-info {
        gap: 40px;

        .wrapper-symbol {
          align-self: flex-end;

          .bg-circle {
            width: 600px;
            height: 600px;
            top: 0px;
            left: -30px;
          }

          img {
            max-width: 230px;
          }
        }
      }
    }

    @media(max-width: 768px) {
      display: flex;
      flex-direction: column;

      .wrapper-info {
        flex-direction: column-reverse;
        padding-bottom: 40px;

        .wrapper-symbol {
          max-width: 100%;
          min-width: 100%;
          justify-content: center;

          .bg-circle {
            width: 768px;
            height: 768px;
            left: -25%;
            border-radius: 44%;
          }
        }
      }

      .title {
        .main-title {
          font-size: 42px;
        }

        .subtitle {
          font-size: 20px;
          line-height: 30px;
        }
      }

      .info-text {
        max-width: 100%;
        margin-top: auto;

        .subtitle {
          font-size: 28px;
        }

        .description {
          font-size: 16px;
          line-height: 24px;
        }
      }
    }

    @media(max-width: 580px) {
      margin-top: 104px;
      min-height: 630px;

      .wrapper-info {
        margin-top: 30px;

        .wrapper-symbol {
          img {
            max-width: 185px;
          }

          .bg-circle {
            max-width: 580px;
            width: calc(100% + 32px);
            height: 830px;
            left: 50%;
            top: 70px;
            transform: translateX(-50%);
            border-radius: 200px 200px 0 0;
          }
        }
      }

      .title {
        margin-top: 0;

        .main-title {
          font-size: 36px;
          line-height: 40px;
          font-weight: 700;
          letter-spacing: 0;
        }

        .subtitle {
          margin-top: 10px;
        }
      }

      .info-text {
        margin-top: 5px;

        .subtitle {
          font-size: 24px;
          line-height: 32px;
        }
      }

      .list-network {
        margin-top: 12px;
      }
    }
  }

  .footer {
    .base-section {
      margin-top: 0;
    }
  }
}