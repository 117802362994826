.link-store {
  padding: 8px 12px 8px 12px;
  background-color: #fff2e7;
  border-radius: 10px;
  display: flex;
  align-items: center;
  border: 1px solid #fff2e7;
  flex-direction: row;
  margin-bottom: 10px;
  width: 193px;
  margin-top: 10px;
  .icon-stack-visited {
    display: none;
  }
  .icon-stack {
    display: block;
  }
  &:hover {
    border: 1px solid #ff8008;
  }

  &:focus {
    background-color: #ff8008;
  }
  .text-wrap {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .name {
    font-size: 16px;
    font-weight: 600;
    line-height: 1.2;
    margin-left: 8px;
  }
  .text {
    margin-left: 8px;
    font-size: 13px;
    font-weight: 400;
    line-height: 1.2;
  }

  .icon-link {
    border: 23px solid #fff;
    color: #ff8008;
    color: #fff;
    width: 105px;
    height: 105px;
    border-radius: 50%;
  }
  &:focus {
    background-color: #ff8008;
    color: #fff;
    .icon-stack-visited {
      display: block;
    }
    .icon-stack {
      display: none;
    }
  }

  @media (max-width: 991px) {
    width: 44px;
    height: 44px;
    padding: 0px;
    justify-content: center;
    margin-right: 10px;
    .name {
      display: none;
    }
    .text-wrap {
      display: none;
    }
    .text {
      display: none;
    }

    .icon-link {
      border-width: 20px;
      width: 96px;
      height: 96px;
      top: -20px;
      right: -20px;
    }
  }
}
