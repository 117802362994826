.item-tag {
  display: flex;
  align-items: center;
  font-weight: 500;
  height: 29px;
  background: #fff2e7;
  border-radius: 10px;
  padding: 0.3rem 0.7rem;
  color: #ff8008;
  height: 44px;
  margin-right: 10px;
  margin-bottom: 10px;
}

.tag {
  display: flex;
  box-sizing: border-box;
  width: 100%;
  flex-direction: row;
  height: 70px;
  display: inline-flex;
  width: fit-content;
  align-items: center;
  border: 2px solid;
  border-image-source: linear-gradient(
    91.25deg,
    rgba(255, 255, 255, 0.31) -3.21%,
    rgba(255, 255, 255, 0) 102.21%
  );
  background: linear-gradient(0deg, #f5f5f5, #f5f5f5),
    linear-gradient(
      91.25deg,
      rgba(255, 255, 255, 0.31) -3.21%,
      rgba(255, 255, 255, 0) 102.21%
    );
  margin-right: 10px;
  padding: 15px 30px 15px 30px;
  border-radius: 10px;
  border: 2px;
  cursor: pointer;

  img {
    display: block;
    margin-right: 10px;
  }
}

@media screen and (max-width: 991px) {
  .tag {
    height: 49px;
    padding: 10px 10px;
  }
}
