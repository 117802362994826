.wrapper-budget {
  display: flex;
  flex-direction: column;
  gap: 5px;
  position: relative;

  .name {
    font-size: 14px;
    font-weight: 600;
    line-height: 1.5;

    span:not(:first-child) {
      color: #FF3408;
      margin-left: 5px;
    }
  }

  .list-budget {
    display: flex;
    border: 1px solid #E0E0E0;
    border-radius: 10px;
    color: #141414;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.3;
    letter-spacing: 0.7px;

    .item-budget {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 25%;
      padding: 12px 20px;
      cursor: pointer;

      &:not(:last-child) {
        border-right: 1px solid #E0E0E0;
      }

      &.selected {
        color: #FF8008;
        font-weight: 500;
      }
    }
  }

  @media(max-width: 992px) {

    .list-budget {
      display: grid;
      grid-template-columns: repeat(2, 1fr);

      .item-budget {
        width: 100%;
        border-bottom: 1px solid #E0E0E0;

        &:not(:last-child) {
          border-right: none;
        }

        &:nth-child(odd) {
          border-right: 1px solid #E0E0E0;
        }

        &:nth-last-child(-n+2) {
          border-bottom: none;
        }
      }
    }
  }

  @media(max-width: 568px) {
    .name {
      display: none;
    }

    .list-budget {
      display: flex;
      flex-direction: column;

      .item-budget {
        width: 100%;


        &:nth-child(odd) {
          border-right: none;
        }

        &:not(:last-child) {
          border-bottom: 1px solid #E0E0E0;
        }
      }
    }
  }
}