.portfolio-item {
  display: flex;
  gap: 12px 30px;
  color: #141414;

  .portfolio-img,
  .content-block {
    width: 50%;
  }

  .portfolio-img {
    align-self: flex-start;
    object-fit: contain;
    border-radius: 20px;
  }

  .content-block {
    height: inherit;
    justify-content: space-between;
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #838588;
    padding-bottom: 30px;
    text-align: start;

    .subtitle {
      font-size: 32px;
      font-weight: 700;
      line-height: 1.25;
    }

    .description {
      margin-top: 20px;
      font-size: 20px;
      font-weight: 500;
      line-height: 1.2;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }

  @media (max-width: 1200px) {
    gap: 12px 20px;

    .content-block {
      .subtitle {
        font-size: 26px;
      }

      .description {
        font-size: 18px;
        margin-top: 16px;
      }
    }
  }

  @media (max-width: 980px) {
    .content-block {
      padding-bottom: 16px;

      .subtitle {
        font-size: 22px;
      }

      .description {
        font-size: 18px;
        margin-top: 12px;
      }

      .go-to-project {
        font-size: 0.875rem;
        font-weight: 700;
        color: #323232;
        display: flex;
        align-items: center;
        letter-spacing: 0.025em;
        text-transform: uppercase;
        .ico-arrow {
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          flex: 60px 0 0;
          width: 60px;
          height: 60px;
          border-radius: 50%;
          color: #000;
          font-size: 1.1rem;
          border: 1px solid #848484;
          margin-left: 1.2rem;
          border-radius: 50%;
          z-index: 1;
        }
        .ico-arrow:after {
          content: "";
          display: block;
        }

        &:hover .ico-arrow:after {
          transform: scale(1);
        }

        .ico-arrow:after {
          height: 39px;
          width: 39px;
          border-radius: 50%;
          background: linear-gradient(
            241.56deg,
            #ff8008 -0.07%,
            #ffc837 64.47%
          );
          position: absolute;
          top: -2px;
          right: -2px;
          transform: scale(0);
          transform-origin: 50% 50%;
          transition: transform 0.3s;
          z-index: 1000;
        }
      }
    }
  }

  @media (max-width: 780px) {
    flex-direction: column;

    .portfolio-img,
    .content-block {
      width: 100%;
    }
  }

  @media (max-width: 580px) {
    .content-block {
      .subtitle {
        font-size: 20px;
        line-height: 1.6;
      }

      .description {
        margin-top: 8px;
      }
    }
  }
}
