.link-messenger {
  height: 40px;
  width: 118px;
  margin-left: 8px;
  margin-right: 8px;
  cursor: pointer;
  border-radius: 10px;
  border-width: 2px;
  border-style: solid;
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  span {
    color: #ffffff;
    font-size: 14px;
    left: 16px;
    font-weight: 400;
  }
  img {
    width: 16px;
    height: 16px;
  }

  &:first-child {
    border-color: #29aaec;
  }

  &:last-child {
    border-color: #43eb61;
  }

  &:hover {
    color: #fff;
  }
  @media (max-width: 768px) {
    height: 40px;
    width: 118px;
    margin-left: 8px;
    margin-right: 8px;
    font-weight: 400;
    cursor: pointer;
    border-radius: 10px;
    border-width: 2px;
    border-style: solid;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 90px;
    gap: 5px;
    span {
      color: #ffffff;
      font-size: 14px;
      left: 16px;
      font-weight: 400;
    }
    img {
      width: 16px;
      height: 16px;
    }

    &:first-child {
      border-color: #29aaec;
    }

    &:last-child {
      border-color: #43eb61;
    }

    &:hover {
      color: #fff;
    }
  }
}
