.base-section {
  .list-portfolio {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    gap: 30px;
  }

  .btn-action {
    cursor: pointer;
    border-radius: 10px;
    background: linear-gradient(90deg, #FF8008 0%, #FD8C06 33.33%, #FB9904 66.67%, #F8B300 100%);
    display: inline-flex;
    padding: 20px 40px;
    justify-content: center;
    align-items: center;
    color: #FFF;
    font-size: 20px;
    font-weight: 700;
    line-height: 1.5;
    letter-spacing: 0.4px;
    margin-top: 60px;
    align-self: center;
  }

  @media (max-width: 1200px) {
    .list-portfolio {
      margin-top: 24px;
    }

    .btn-action {
      margin-top: 40px;
      padding: 15px 30px;
    }
  }

  @media(max-width: 580px) {
    .list-portfolio {
      gap: 16px;
    }

    .btn-action {
      margin-top: 32px;
      width: 100%;
      border-radius: 12px;
      padding: 14px 24px;
      font-size: 16px;
      font-weight: 600;
    }
  }
}