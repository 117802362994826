.list-links {
  padding-bottom: 10px;
  @media (max-width: 991px) {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
  }
}
