.services-wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-top: 150px;

  .title {
    font-weight: 700;
    font-size: 48px;
    line-height: 60px;
    text-align: center;
    padding-top: 60px;
  }

  .services-block {
    display: flex;
    justify-content: flex-end;
    background: linear-gradient(to bottom right, #ff8008, #f8b300);

    .title {
      color: #fff;
    }

    .block-wrapper {
      max-width: 445px;
      margin-right: 110px;

      .cards-wrapper {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;
        margin-top: 40px;
        margin-bottom: 88px;

        .card-item {
          background: #fff;
          border-radius: 28px;
          padding: 0px 24px 24px;
          text-align: left;
          transition: all 0.3s linear;

          &:hover {
            transform: scale(1.1, 1.1);
          }

          .item-img {
            margin-left: 80px;
            border-radius: 0 28px 0 0;
          }
          .item-text {
            font-weight: 700;
            font-size: 18px;
            line-height: 1.3;
            color: #323232;
            margin: -15px 0px 0px;

            &.text__bottom {
              padding-top: 23px;
            }
          }
        }
      }
    }
  }
  .industries-block {
    display: flex;
    justify-content: flex-start;
    background: #f1f3f6;
    .title {
      color: #141414;
    }
    .block-wrapper {
      max-width: 445px;
      margin-left: 110px;
    }
    .cards-wrapper {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 70px;
      margin-top: 82px;
      margin-bottom: 88px;

      .card-item {
        .item-cases {
          display: flex;
          gap: 23px;

          .item-img {
            width: 81px;
            height: 81px;
            border-radius: 16px;
            transition: all 0.2s linear;

            &:hover {
              transform: scale(1.2, 1.2);
            }
          }
        }

        .item-text {
          border: 1px solid #323232;
          color: #323232;
          text-align: center;
          padding: 10px 0px;
          border-radius: 38px;
          font-size: 20px;
          line-height: 1.3;
          font-weight: 500;
          margin-top: 40px;

          &:hover {
            background: rgb(50, 50, 50, 0.06) !important;
          }
        }
      }
    }
  }
  @media (max-width: 1200px) {
    .services-block {
      .block-wrapper {
        margin-right: 35px;
      }
    }
    .industries-block {
      .block-wrapper {
        margin-left: 35px;
      }
    }
  }
  @media (max-width: 991px) {
    grid-template-columns: repeat(1, 1fr);

    .services-block,
    .industries-block {
      justify-content: center;
      .block-wrapper {
        margin: 0px;
      }
    }

    .services-block {
      .block-wrapper {
        max-width: 725px;
        .cards-wrapper {
          grid-template-columns: repeat(3, 1fr);

          .card-item {
            .item-img {
              margin-left: 98px;
              margin-top: 0px;
            }
          }
        }
      }
    }
  }

  @media (max-width: 746px) {
    .services-block {
      .block-wrapper {
        max-width: 350px;
        .cards-wrapper {
          grid-template-columns: repeat(2, 1fr);
          padding: 0px 15px;

          .card-item {
            padding: 0px 0px 12px 12px;
            .item-img {
              margin-left: 30px;
            }
            .item-text {
              font-size: 14px;

              &.text__bottom {
                padding-top: 18px;
              }
            }
          }
        }
      }
    }
  }
  @media (max-width: 470px) {
    .cards-wrapper {
      padding: 0px 15px;
    }
    .industries-block {
      .cards-wrapper {
        gap: 30px;
        .card-item {
          .item-cases {
            gap: 10px;
          }
        }
      }
    }
  }
}
