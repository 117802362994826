.setup_discuss-box {
  width: 100%;
  background: rgb(255, 128, 8);
  background: linear-gradient(
    90deg,
    rgba(255, 128, 8, 1) 0%,
    rgba(247, 179, 0, 1) 67%
  );
  color: white;
  background-size: cover;
  border: 2px solid #ffffff;
  box-shadow: 0px 4px 13px rgba(20, 28, 60, 0.08);
  border-radius: 20px;
  padding: 1.5rem 1.8rem;
  margin-top: 2rem;
  overflow: hidden;
  position: relative;
  z-index: 1;
  .h4 {
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 1rem;
  }

  .js_discuss-btn {
    width: 135px;
    cursor: pointer;
  }

  .medium-text {
    font-weight: 500;
  }
  @media (max-width: 760px) {
    text-align: left;
  }
}
